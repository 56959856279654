import { lazy } from "react";
import { serviceProviderRole } from "./routeConfig";

export const serviceProviderRoutes = [
  {
    path: "/serviceProvider/dashboard",
    component: lazy(() => import("../Pages/ServiceProviderPages/Dashboard")),
    exact: true,
    isPrivate: true,
    theme: "light",
    role: serviceProviderRole,
  },
  {
    path: "/serviceProvider/resetPassword",
    component: lazy(() =>
      import("../Pages/ServiceProviderPages/ResetPassword")
    ),
    exact: true,
    isPrivate: true,
    theme: "light",
    role: serviceProviderRole,
  },
  {
    path: "/serviceProvider/subscriber",
    component: lazy(() => import("../Pages/ServiceProviderPages/TraineeList")),
    exact: true,
    isPrivate: true,
    theme: "light",
    role: serviceProviderRole,
  },
  
 
  {
    path: "/serviceProvider/editProfile",
    component: lazy(() =>
      import("../Pages/ServiceProviderPages/EditProfile")
    ),
    exact: true,
    isPrivate: true,
    theme: "light",
    role: serviceProviderRole,
  },
];
