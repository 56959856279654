import { createSlice } from "@reduxjs/toolkit";
import {
  addUser,
  addCategory,
  getAllUser,
  updateUser,
  addGallery,
  deleteUser,
  
} from "./addUserApi";

export const addUserSlice = createSlice({
  name: "addUser",
  initialState: {
    error: null,
    success: null,
    loading: "idle",
  },
  extraReducers: (builder) => {
    builder
    .addCase(addUser.pending, (state) => {
      state.loading = "pending";
    })
    .addCase(addUser.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("action user payload....",action.payload)

      state.user = action?.payload?.data;
    })
    .addCase(addUser.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload.error;
    })
      .addCase(getAllUser.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllUser.fulfilled, (state) => {
        state.loading = "succeeded";
        state.success = true;
      })
      .addCase(getAllUser.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.error;
      })
      .addCase(updateUser.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.loading = "succeeded";
        state.success = true;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.error;
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.loading = "succeeded";
        state.success = true;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.error;
      })
      // gallerry
      .addCase(addGallery.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(addGallery.fulfilled, (state, action) => {
        state.loading = "succeeded";
        console.log("action user payload....",action.payload)
  
        state.user = action?.payload?.data;
      })
      .addCase(addGallery.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload.error;
      })
       
  },
});

export default addUserSlice.reducer;
