import React, { useState, useEffect, memo, useCallback } from "react";
import {
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  CardFooter,
} from "reactstrap";

import { BiHome } from "react-icons/bi";
import FillBtn from "../Buttons/FillBtn";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import OutlineBtn from "../Buttons/OutlineBtn";
import { PiCaretDownBold } from "react-icons/pi";
import { RiDashboardFill } from "react-icons/ri";
import { FaBars, FaUserEdit } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { GiWallet, GiBodyBalance } from "react-icons/gi";
import InformationModal from "../Modal/InformationModal";
 
 
import Images from "../../HelperMethods/Constants/ImgConstants";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { PiUsersFourThin, PiAddressBookBold } from "react-icons/pi";
import { FaKey, FaTrashCan, FaCircleArrowUp } from "react-icons/fa6";
 
import {
 
  TRAINEE_TYPE,
  TRAINER_TYPE,
  NUTRITIONIST_TYPE,
} from "../../utils/constants";

const TopBar = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

 
  const roleType = localStorage.getItem("role");
 
  useEffect(() => {
    if (props.isPublic) {
      window.addEventListener("scroll", listenScrollEvent);

      if (location.pathname === "/registerAs") {
        setShowNavItems(false);
      }
    }

    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isPublic, location.pathname]);

  useEffect(() => {
    if (
      props?.isPublic &&
      (location.pathname === "/registerAs" || props?.isAuth)
    ) {
      setShowNavItems(false);
    } else {
      setShowNavItems(true);
    }

    if (
      props.isPublic &&
      (location.pathname === "/termAndCondition" ||
        location.pathname === "/signIn" ||
        location.pathname === "/contactUs" ||
        location.pathname === "/forgotPassword")
    ) {
      setShowTopBar(false);
    } else {
      setShowTopBar(true);
    }
  }, [location.pathname, props?.isAuth, props.isPublic]);

  const [showTopBar, setShowTopBar] = useState(true);
  const [isSliding, setIsSliding] = useState(false);
  const [showNavItems, setShowNavItems] = useState(true);
  const [
    showSubscriptionInformationModal,
    setShowSubscriptionInformationModal,
  ] = useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);

  const [backgroundClass, setBackgroundClass] = useState(
    props.isPublic
      ? props?.isGuest
        ? "bg-white-custom"
        : "bg-transparent"
      : "bg-white-custom"
  );

  const textClass = props.isPublic
    ? props?.isGuest
      ? "text-black-custom"
      : "text-white"
    : "text-black-custom";

  const listenScrollEvent = () => {
    if (!props?.isGuest && props?.isPublic) {
      if (window.scrollY > 180) {
        setBackgroundClass("customBgDark");
      } else {
        setBackgroundClass("bg-transparent");
      }
    }
  };

  

  const handleSignUpClick = useCallback(() => {
    navigate("/registerAs");
  }, [navigate]);

  const slide = () => {
    setIsSliding(!isSliding);
  };

  const handleSignInClick = useCallback(() => {
    navigate("/signIn");
  }, [navigate]);

  const handleSubscriptionInformationModalClose = useCallback(() => {
    setShowSubscriptionInformationModal(false);
  }, []);

  const handleDeleteAccountModalClose = useCallback(() => {
    setDeleteAccountModal(false);
  }, []);

  const handleDeleteAccountClick = useCallback(() => {
    setDeleteAccountModal(false);
  }, []);

  const handleDeleteClick = () => {
    setDeleteAccountModal(true);
  };

  const handleLogoutClick = () => {
    localStorage.removeItem("role");
    navigate("/");
  };

  const handleSubscriptionClick = useCallback(() => {
    navigate("/registerAs");
  }, [navigate]);

  const handleFitneeCommunityClick = useCallback(() => {
    navigate("/guestGallery");
  }, []);

  return (
    <>
      {showTopBar && (
        <div>
          <Navbar
            className={`${styles.navbar} ${backgroundClass} p-2 `}
            expand="lg"
            fixed="top"
          >
            <Link
              to={
                roleType === TRAINEE_TYPE
                  ? "/trainee/dashboard"
                  : roleType === TRAINER_TYPE || roleType === NUTRITIONIST_TYPE
                  ? "/serviceProvider/dashboard"
                  : "/"
              }
            >
              <h3 style={{color:"#F3E11C"}} className="fw-bold ">The Studios Collection</h3>
            </Link>
            {showNavItems && (
              <>
                <NavbarToggler
                  className={"textYellow border-0 pb-2"}
                  onClick={slide}
                >
                  <FaBars />
                </NavbarToggler>
                {roleType === null && (
                  <Nav className={"mx-auto gap-2 d-lg-flex d-none"} navbar>
                    <NavItem className={`${styles.navItem}`}>
                      <Link
                        className={`nav-link ${styles.navLink} ${textClass}`}
                        to="/"
                      >
                        <span className={`borderHover`}>
                       
                          HOME
                        </span>
                      </Link>
                    </NavItem>
                    <NavItem>
                    <Link
                        className={`nav-link ${styles.navLink} ${textClass}`}
                        to= "/guestGallery"
                      >
                        <span className={`borderHover`}>
                       
                        GALLERY
                        </span>
                      </Link>
                    </NavItem>
                
                    <NavItem>
                      <Link
                        className={`nav-link ${styles.navLink} ${textClass}`}
                        to="/contactUs"
                      >
                        <span className={`borderHover`}>
                        CONTACT US
                        </span>
                      </Link>
                    </NavItem>
                  </Nav>
                )}

                {!props?.isGuest && !props?.isPrivate && roleType === null && (
                  <Nav className={`ml-auto d-lg-flex d-none ${styles.nav}`}>
                  
                    <FillBtn
                      className="px-3 shadow-none"
                      text="Login"
                      handleOnClick={handleSignInClick}
                    />
                    {/* <OutlineBtn
                      className="px-3 shadow-none"
                      text={t("landing.signInText")}
                      handleOnClick={handleSignInClick}
                    /> */}
                  </Nav>
                )}

                {!props?.isGuest && !props?.isPublic && (
                  <Nav className={`ml-auto d-lg-flex d-none ${styles.nav}`}>
                    <UncontrolledDropdown>
                      <DropdownToggle className="p-0" nav>
                        <div
                          className="bgProperties rounded-circle"
                          style={{
                            backgroundImage: `url(${Images.DUMMY_USERUPLOAD_PIC})`,
                            width: "40px",
                            height: "40px",
                          }}
                        ></div>
                      </DropdownToggle>
                      <DropdownMenu style={{ right: 0, left: "auto" }}>
                      
                      
                      
 
                     
                        <DropdownItem>
                          <div
                            className="d-flex align-items-center"
                            onClick={handleLogoutClick}
                          >
                            <span className="textParrotGreen me-2 d-flex">
                              <FaCircleArrowUp className="mb-1" />
                            </span>
                            <p className="text-black-custom mb-0">Logout</p>
                          </div>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Nav>
                )}
              </>
            )}
          </Navbar>
          <div
            className={`d-lg-none d-block bg-black ${styles.mobileView} h-100 ${
              isSliding ? styles["slide-right"] : styles["slide-left"]
            }`}
          >
            <Card className="bg-transparent border-0 h-100">
              <CardBody className="p-0 p-2 mt-2">
                <Nav className={`mx-auto my-5 gap-2 ${styles.nav}`} navbar>
                  <NavItem className={`${styles.NavItem}`}>
                    <NavLink className={`${styles.NavLink}`} href="/">
                      <BiHome className={`fs-2 me-3 text-white`} />
          Home
                    </NavLink>
                  </NavItem>

               

                  <NavItem className={`${styles.NavItem}`}>
                    <NavLink
                      className={`${styles.NavLink}`}
                      href="#"
                      onClick={handleFitneeCommunityClick}
                    >
                      <PiUsersFourThin className={`fs-2 me-3 text-white`} />
                      Gallery
                    </NavLink>
                  </NavItem>

                  <NavItem className={`${styles.NavItem}`}>
                    <NavLink className={`${styles.NavLink}`} href="/contactUs">
                      <PiAddressBookBold className={`fs-2 me-3 text-white`} />
                    Contact Us
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardBody>
              <CardFooter className="border-0">
                {!props?.isGuest && (
                  <Nav className={`ml-auto d-lg-none d-block ${styles.nav}`}>
                    <FillBtn
                      className="px-3 w-100 mb-2"
                      text= "Sign Up"
                      handleOnClick={handleSignUpClick}
                    />
                    <OutlineBtn
                      className="px-3 w-100"
                      text= "Sign In"
                      handleOnClick={handleSignInClick}
                    />
                  </Nav>
                )}
              </CardFooter>
            </Card>
          </div>
          <div
            onClick={slide}
            className={`d-lg-none d-block overflow-hidden ${
              styles.bgInverse
            } h-100 ${
              isSliding
                ? styles["slide-left-blank"]
                : styles["slide-right-blank"]
            }`}
          ></div>
        </div>
      )}
{/* 
      <InformationModal
        size={"md"}
        TOneClassName={"fw-bold mb-4 fs-5 text-center"}
        className={"p-4"}
        isOpen={showSubscriptionInformationModal}
        onClose={handleSubscriptionInformationModalClose}
        ModalTextOne="Subscribe and then download the app so you can access FitNee Community"
        ButtonOne={
          <FillBtn
            text={t("guest.subscribeText")}
            className="py-2 px-5"
            handleOnClick={handleSubscriptionClick}
          />
        }
        ButtonTwo={
          <OutlineBtn
            text={t("guest.notNowText")}
            className="py-2 px-5"
            handleOnClick={handleSubscriptionInformationModalClose}
          />
        }
      />

      <InformationModal
        size={"md"}
        TOneClassName={"fw-bold mb-4 fs-5 text-center"}
        className={"p-4"}
        isOpen={deleteAccountModal}
        onClose={handleDeleteAccountModalClose}
        ModalTextOne="Are you sure to want to delete your account?"
        ButtonOne={
          <FillBtn
            text={t("signup.yesText")}
            className="py-2 px-5"
            handleOnClick={handleDeleteAccountClick}
          />
        }
        ButtonTwo={
          <OutlineBtn
            text={"No"}
            className="py-2 px-5"
            handleOnClick={handleDeleteAccountModalClose}
          />
        }
      /> */}
    </>
  );
};

export default memo(TopBar);
