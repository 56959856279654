import { lazy } from "react";
import { adminRole } from "./routeConfig";

export const adminRoutes = [
  {
    path: "/admin/dashboard",
    component: lazy(() => import("../Pages/AdminPages/Dashboard")),
    exact: true,
    role: adminRole,
    isAdmin: true,
  },
  {
    path: "/admin/user/addUser",
    component: lazy(() => import("../Pages/AdminPages/Users/AddUser/index")),
    exact: true,
    role: adminRole,
    isAdmin: true,
  },
  {
    path: "/admin/user/viewUser",
    component: lazy(() =>
      import("../Pages/AdminPages/Users/ViewUser/index")
    ),
    exact: true,
    role: adminRole,
    isAdmin: true,
  },
  {
    path: "/admin/project/addProject",
    component: lazy(() => import("../Pages/AdminPages/Projects/AddProject/index")),
    exact: true,
    role: adminRole,
    isAdmin: true,
  },
  {
    path: "/admin/project/viewProject",
    component: lazy(() => import("../Pages/AdminPages/Projects/ViewProject/index")),
    exact: true,
    role: adminRole,
    isAdmin: true,
  },

  // Gallery

  {
    path: "/admin/addGallery",
    component: lazy(() => import("../Pages/AdminPages/UploadGallery/AddGalleryForm/index")),
    exact: true,
    role: adminRole,
    isAdmin: true,
  },
  {
    path: "/admin/viewGallery",
    component: lazy(() => import("../Pages/AdminPages/UploadGallery/ViewGalleryItems/index")),
    exact: true,
    role: adminRole,
    isAdmin: true,
  },
];
