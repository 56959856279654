import React from "react";
import SubMenu from "./SubMenu";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { FaRegUser } from "react-icons/fa"
import { FaUsers } from "react-icons/fa6";
import { ImPaste } from "react-icons/im";
import { MdOutlineCollections } from "react-icons/md";
import { NavItem, NavLink, Nav } from "reactstrap";
 
import {
  GoHome,
  GoPersonAdd,
  GoPaste,
  GoLog,
  GoSortAsc,
  GoTools,
} from "react-icons/go";

const SideBar = ({ isOpen, toggle }) => (
  
  <div className={classNames("sidebar",  { "is-open": isOpen })}>
    <div className="sidebar-header">
      <span color="info" onClick={toggle} style={{ color: "#FFFFFF" }}>
        &times;
      </span>
      {/* <img className="py-5" src={Logo} alt={"website-logo"} />
       */}
       <h6 style={{color:"#F3E11C"}} className="fw-bold text-start pt-5 text-center">The Studios Collection</h6>
    </div>
    <div className="d-flex justify-content-center">
      <Nav vertical className="list-unstyled pb-5"  >
        <NavItem>
          <NavLink
            className="py-3 mt-4 d-flex align-items-center gap-3 text-white"
            tag={Link}
            to={"/admin/dashboard"}
          >
            <RxDashboard size={30} color="#F3E11C" />
            Dashboard
          </NavLink>
        </NavItem>

        {/* <SubMenu title="Users" icon={<GoPersonAdd />} items={submenus[1]} /> */}
        <NavItem>
          <NavLink
            className="py-3 d-flex align-items-center gap-3 text-white"
            tag={Link}
            to={"/admin/user/addUser"}
          >
            <FaRegUser size={30} color="#F3E11C" />
           Add User
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className="py-3 d-flex align-items-center gap-3 text-white"
            tag={Link}
            to={"/admin/user/viewUser"}
          >
            <FaUsers size={30} color="#F3E11C" />
            View Users
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className="py-3 d-flex align-items-center gap-3 text-white"
            tag={Link}
            to={"/admin/project/addProject"}
          >
            <GoPaste size={30} color="#F3E11C" />
            Add Project
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className="py-3 d-flex align-items-center gap-3 text-white"
            tag={Link}
            to={"/admin/project/viewProject"}
          >
            <ImPaste size={30} color="#F3E11C" />
            View Projects
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className="py-3 d-flex align-items-center gap-3 text-white"
            tag={Link}
            to={"/admin/addGallery"}
          >
           
            <MdOutlineCollections size={30} color="#F3E11C" />
            Add Gallery Item
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className="py-3 d-flex align-items-center gap-3 text-white"
            tag={Link}
            to={"/admin/viewGallery"}
          >
           
            <MdOutlineCollections size={30} color="#F3E11C" />
            View Gallery Items
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className="py-3 d-flex align-items-center gap-3 text-white"
            tag={Link}
            to={"/contact"}
          >
            {/* <GoTools /> */}
            
          </NavLink>
        </NavItem>
       
      </Nav>
    </div>
  </div>
);

const submenus = [
  [
    {
      title: "Home 1",
      target: "Home-1",
    },
    {
      title: "Home 2",
      target: "Home-2",
    },
    {
      itle: "Home 3",
      target: "Home-3",
    },
  ],
  [
    {
      title: "Service Provider List",
      target: "/admin/Users/ServiceProviderList",
    },
    {
      title: "Trainee List",
      target: "/admin/Users/TraineeList",
    },
  ],
];

export default SideBar;
