// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import { Toaster } from "react-hot-toast";
import "./App.css";
import routes from "./Routes/AllRoutes";
import { useDispatch } from "react-redux";
import React, { Suspense, useEffect } from "react";
import { PublicRoute } from "./Routes/PublicRoutes";
import { DEFAULT_LANGUAGE } from "./utils/constants";
import AdminLayout from "./Pages/Layout/AdminLayout";
import { PrivateRoute } from "./Routes/PrivateRoutes";
 
import GeneralLayout from "./Pages/Layout/GeneralLayout";
import LoadingScreen from "./HelperMethods/LoadingScreen";
 
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function withLayout(
  WrappedComponent,
  isPublic,
  isGuest,
  isPrivate,
  isAuth,
  theme = "black",
  isAdmin
) {
  const admin = isAdmin;

  return class extends React.Component {
    render() {
      return (
        <>
        <Toaster />
          {admin ? (
            <AdminLayout>
              <WrappedComponent></WrappedComponent>
            </AdminLayout>
          ) : (
            <GeneralLayout
              isPublic={isPublic}
              isGuest={isGuest}
              isPrivate={isPrivate}
              isAuth={isAuth}
              theme={theme}
            >
              <WrappedComponent></WrappedComponent>
            </GeneralLayout>
          )}
        </>
      );
    }
  };
}

function App() {
  const dispatch = useDispatch();

 

  return (
    <React.Fragment>
      <Router>
        <Routes>
          {routes.map((route, index) => {
            const Component = route.component;
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    {!route.isPublic ? (
                      <PrivateRoute
                        props={route}
                        role={route?.role}
                        Component={withLayout(
                          Component,
                          route?.isPublic,
                          route?.isGuest,
                          route?.isPrivate,
                          route?.isAuth,
                          route?.theme,
                          route?.isAdmin
                        )}
                      />
                    ) : (
                      <PublicRoute
                        props={route}
                        role={route?.role}
                        Component={withLayout(
                          Component,
                          route?.isPublic,
                          route?.isGuest,
                          route?.isPrivate,
                          route?.isAuth,
                          route?.theme
                        )}
                      />
                    )}
                  </Suspense>
                }
              />
            );
          })}
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
