export const adminRole = 1;
export const guestRole = 2;
export const traineeRole = 3;
export const vendorRole = 3;
export const trainerRole = 4;
export const clientRole = 4;
export const workerRole = 5;
export const managerRole = 2;
export const nutritionistRole = 5;
export const trainerNutritionRole = 6;
export const allRoles = [1, 2, 3, 4, 5];
export const serviceProviderRole = [4,5,6];
