let Images = {
  // =============================landingPageImgs===================================
  LOGO_IMG: require("../../Assets/Images/LOGO.svg").default,
 
  ADMIN_IMG: require("../../Assets/Images/stephan.jpeg"),
  MANAGER_IMG: require("../../Assets/Images/jeff.jpeg"),
  CLIENT_IMG: require("../../Assets/Images/client.jpg"),
 
  GALLERY_IMG1: require("../../Assets/galleryItem/item3.jpg"),
  GALLERY_IMG2: require("../../Assets/galleryItem/item4.jpg"),
  GALLERY_IMG3: require("../../Assets/galleryItem/item5.jpg"),
 
  DUMMY_UPLOAD_DOC: require("../../Assets/Images/uploadImg.svg").default,
  DUMMY_USERUPLOAD_PIC: require("../../Assets/Images/userDummyImg.png"),
 
 
  GOAL_IMG: require("../../Assets/img/slide1.jpg"),
  VISION_IMG: require("../../Assets/img/bg1.jpg"),
  ABOUT_IMG: require("../../Assets/img/about.jpg"),
  ABOUT_IMG1: require("../../Assets/img/register.jpg"),
  CONTACT_PIC: require("../../Assets/img/bg2.jpg"),
  USER_DUMMY_IMG: require("../../Assets/Images/ConstImgs/userDummyImg.png"),
  DEFAULT_USER_IMG: require("../../Assets/Images/defaultImg.svg").default,
  
  FEATURE_ONE: require("../../Assets/img/portfolio-1.jpg"),
  FEATURE_TWO: require("../../Assets/img/portfolio-2.jpg"),
  FEATURE_THREE: require("../../Assets/img/portfolio-3.jpg"),
  FEATURE_FOUR: require("../../Assets/img/portfolio-3.jpg"),
  FEATURE_FIVE: require("../../Assets/img/portfolio-3.jpg"),
  SLIDE_ONE: require("../../Assets/img/g2.jpg"),
  SLIDE_TWO: require("../../Assets/img/g1.jpg"),
  SLIDE_THREE: require("../../Assets/img/g5.jpg"),
  SLIDE_FOUR: require("../../Assets/img/g4.jpg"),
 
  INSTA_IMG: require("../../Assets/Images/homeScreen/socialMedia/Insta.svg")
    .default,
  TIKTOK_IMG: require("../../Assets/Images/homeScreen/socialMedia/Ticktok.svg")
    .default,
 
  TWITTER_IMG: require("../../Assets/Images/homeScreen/socialMedia/Twitter.svg")
    .default,
  YOUTUBE_IMG: require("../../Assets/Images/homeScreen/socialMedia/Youtube.svg")
    .default,
  HERO_TEXT_BACKGROUND_IMG: require("../../Assets/Images/homeScreen/HeroTextBgImage.png"),
  HERO_BACKGROUND_IMG:
    require("../../Assets/Images/homeScreen/HeroBackground.svg").default,
  // =============================sliderImgs===================================
  
 
  //================================loginImgs==================================
  
  
  EMAIL_ICON: require("../../Assets/Images/InputField/emailLogo.svg").default,
  PHONE_ICON: require("../../Assets/Images/InputField/phoneLogo.svg").default,
  PERSON_ICON: require("../../Assets/Images/InputField/personLogo.svg").default,
  //==============================registerAsImgs===============================
  REGISTER: require("../../Assets/img/register.jpg").default,
 
  //==============================ConstImgs====================================
  ARROW_DOWN_IMG: require("../../Assets/Images/ConstImgs/ArrowDown.svg")
    .default,
  ARROW_UP_IMG: require("../../Assets/Images/ConstImgs/ArrowUp.svg").default,
  CARD_ICON_IMG: require("../../Assets/Images/ConstImgs/CardIcon.svg").default,
 
  CAMERA_IMG: require("../../Assets/Images/ConstImgs/Frame.svg").default,
  GREEN_CIRCLE_IMG: require("../../Assets/Images/ConstImgs/greenCircle.png")
    .default,
  YELLOW_CIRCLE_IMG: require("../../Assets/Images/ConstImgs/yellowCircle.png")
    .default,
  UPLOAD_BG_IMG: require("../../Assets/Images/ConstImgs/uploadImg.svg").default,
  UPLOAD_ICON: require("../../Assets/Images/ConstImgs/uploadImg.svg").default,
  PASSWORD_ICON_IMG: require("../../Assets/Images/ConstImgs/passwordIcon.svg").default,
  //==============================Trainee Images====================================
  TRAINEE_GRAPH_IMG: require("../../Assets/Images/TraineeGraph.svg").default,
 
  TEMP_CARD_TWO: require("../../Assets/Images/userDummyImg.png"),
  //==============================appStore and Google btn Images====================================
 
 
};

export default Images;
