const { TRAINEE_ROLE, TRAINEE_INITIAL_URL , MANAGER_ROLE, WORKER_ROLE,CLIENT_ROLE,VENDOR_ROLE , ADMIN_ROLE , MANAGER_INITIAL_URL, VENDOR_INITIAL_URL, CLIENT_INITIAL_URL,WORKER_INITIAL_URL , ADMIN_INITIAL_URL } = require("./constants");

 

 

const getGuestDataLimit = () => {
  return 5;
};

const createFormData = (data) => {
  const formData = new FormData();

  for (const key in data) {
    if (data.hasOwnProperty(key) && data[key] !== "" && data[key] !== null) {
      if (Array.isArray(data[key]) && data[key].length > 0) {
        if (key === "avatar") {
          data[key].forEach((avatar) => {
            formData.append(`avatar`, avatar);
          });
        } else if (key === "certificate_files") {
          data[key].forEach((certificate_files) => {
            formData.append(`certificate_files`, certificate_files);
          });
        } else if (key === "subscription_plans") {
          const updated_subscription_plans = data[key].map(
            (subscription_plan) => {
              return { ...subscription_plan, membership_type: data["role"] };
            }
          );
          formData.append(
            "subscription_plans",
            JSON.stringify(updated_subscription_plans)
          );
        } else {
          formData.append(key, JSON.stringify(data[key]));
        }
      } else if (!Array.isArray(data[key])) {
        formData.append(key, data[key]);
      }
    }
  }

  return formData;
};

const getRoleString = (roleId) => {
  if (roleId === 1) {
    return "Admin";
  } else if (roleId === 2) {
    return "Manager"
  } else if (roleId === 3) {
    return "Vendor"
  } else if (roleId === 4) {
    return "Client"
  } else if (roleId === 5) {
    return "Worker"
  }
}

const getInitialUrl = (role) => {
  let initialUrl = "";

  switch (role) {
    case ADMIN_ROLE:
      initialUrl = ADMIN_INITIAL_URL;
      break;
    case MANAGER_ROLE:
      initialUrl = MANAGER_INITIAL_URL;
      break;
    case CLIENT_ROLE:
      initialUrl = CLIENT_INITIAL_URL;
      break;
    case VENDOR_ROLE:
      initialUrl = VENDOR_INITIAL_URL;
      break;
    case WORKER_ROLE:
      initialUrl = WORKER_INITIAL_URL;
      break;
    default:
      break;
  }

  return initialUrl;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getGuestDataLimit,
  getInitialUrl,
  getRoleString,
  createFormData
};
