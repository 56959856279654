import { Container } from "reactstrap";
import React, { useEffect } from "react";
import styles from "./style.module.scss";
import TopBar from "../../../Shared/TopBar";
import { useLocation } from "react-router-dom";

const GeneralLayout = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, [pathname]);

  return (
    <React.Fragment>
     
        <TopBar
          isPublic={props.isPublic}
          isGuest={props.isGuest}
          isPrivate={props.isPrivate}
          isAuth={props.isAuth}
        />
      
          {props.children}
      
     
    </React.Fragment>
  );
};

export default GeneralLayout;
