import { lazy } from "react";
import { guestRole } from "./routeConfig";

export const guestRoutes = [
  // {
  //   path: "/guest/gallery",
  //   component: lazy(() => import("../Pages/GuestPages/GalleryList")),
  //   exact: true,
  //   isPublic: true,
  //   role: guestRole,
  //   theme: "light",
  //   isGuest: true,
  // },
  
 
 
 
];
