import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import userSlice from "./features/User/userSlice.js";
 
import { persistStore, persistReducer } from "redux-persist";
import forgotPasswordSlice from "./features/ForgotPassword/forgotPasswordSlice.js";
import addUserSlice from "./features/Admin/addUser/addUserSlice.js";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
 
 
 

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  user: userSlice,
  forgotPassword: forgotPasswordSlice,
  addUser: addUserSlice,
 
  
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
