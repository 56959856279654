import React from "react";
import { Spinner } from "reactstrap";
import styles from "./style.module.scss";
import { Audio } from "react-loader-spinner";
const LoadingScreen = () => (
  <div className={styles.loadingOverlay}>
    <div className={styles.loadingContent}>
      <Audio
        height="80"
        width="80"
        radius="9"
        color="#f6e709"
        ariaLabel="three-dots-loading"
        wrapperStyle
        wrapperClass
      />
    </div>
  </div>
);

export default LoadingScreen;
