import { lazy } from "react";
import { workerRole } from "./routeConfig";

export const WorkerRoutes = [
  {
    path: "/worker/dashboard",
    component: lazy(() => import("../Pages/WorkerPages/Dashboard")),
    exact: true,
    isPrivate: true,
    theme: "light",
    role: workerRole,
  },
  {
    path: "/worker/projects",
    component: lazy(() => import("../Pages/WorkerPages/Project")),
    exact: true,
    isPrivate: true,
    theme: "light",
    role: workerRole,
  },
  {
    path: "/worker/projectRoom/:id",
    component: lazy(() => import("../Pages/WorkerPages/ProjectRoom")),
    exact: true,
    isPrivate: true,
    theme: "light",
    role: workerRole,
  },
  // {
  //   path: "/trainee/subscriptionHistory",
  //   component: lazy(() => import("../Pages/TraineePages/SubscriptionDetail")),
  //   exact: true,
  //   isPrivate: true,
  //   theme: "light",
  //   role: traineeRole,
  // },
  // {
  //   path: "/trainee/myProgress",
  //   component: lazy(() => import("../Pages/TraineePages/MyProgress")),
  //   exact: true,
  //   isPrivate: true,
  //   theme: "light",
  //   role: traineeRole,
  // },
  // {
  //   path: "/trainee/allServiceProvider/:roleType",
  //   component: lazy(() => import("../Pages/TraineePages/AllServiceProvider")),
  //   exact: true,
  //   isPrivate: true,
  //   theme: "light",
  //   role: traineeRole,
  // },
  // {
  //   path: "/trainee/serviceProviderProfile",
  //   component: lazy(() =>
  //     import("../Pages/TraineePages/ServiceProviderProfile")
  //   ),
  //   exact: true,
  //   isPrivate: true,
  //   theme: "light",
  //   role: traineeRole,
  // },
  // {
  //   path: "/trainee/subscriptions",
  //   component: lazy(() => import("../Pages/TraineePages/Subscriptions")),
  //   exact: true,
  //   isPrivate: true,
  //   theme: "light",
  //   role: traineeRole,
  // },
  // {
  //   path: "/trainee/subscriptions/creditCardDetail",
  //   component: lazy(() => import("../Pages/TraineePages/CreditCardDetail")),
  //   exact: true,
  //   isPrivate: true,
  //   theme: "light",
  //   role: traineeRole,
  // },
  // {
  //   path: "/trainee/appDownloadLink",
  //   component: lazy(() => import("../Pages/TraineePages/AppDownloadLink")),
  //   exact: true,
  //   isPrivate: true,
  //   theme: "light",
  //   role: traineeRole,
  // },
  // {
  //   path: "/trainee/resetPassword",
  //   component: lazy(() => import("../Pages/TraineePages/ResetPassword")),
  //   exact: true,
  //   isPrivate: true,
  //   theme: "light",
  //   role: traineeRole,
  // },
  // {
  //   path: "/trainee/editProfile",
  //   component: lazy(() => import("../Pages/TraineePages/EditProfile")),
  //   exact: true,
  //   isPrivate: true,
  //   theme: "light",
  //   role: traineeRole,
  // },
];
